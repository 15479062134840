import React, { memo, useEffect, useState } from "react";
import type { ReactElement } from "react";
import ReactPlayer from "react-player";
import { useWindows } from "../util/useWindows";
import { shallow } from "zustand/shallow";

interface VideoProps {}

function Video({}: VideoProps): ReactElement {
  const [innerWidth, innerHeight] = useWindows((s) => [s.innerWidth, s.innerHeight], shallow);
  const [headerHeight, setHeight] = useState(0);
  useEffect(() => {
    setTimeout(() => setHeight(document.querySelectorAll("header")[0]?.getBoundingClientRect().height), 200);
  }, []);
  return (
    <div data-testid="video-div" className="w-full overflow-hidden flex items-center justify-center">
      <div className="mx-auto">{headerHeight && <ReactPlayer playing url={require("url:/assets/video.mp4")} muted loop playsinline width={100 * innerWidth} height={Math.max(innerHeight - headerHeight, (innerWidth / 16) * 9)} />}</div>
    </div>
  );
}

export default memo(Video);
