import React, { memo, useState } from "react";
import type { ReactElement } from "react";
import logoSvg from "/assets/logo.svg";
import logoMSvg from "/assets/header-logo-m.svg";
import menuMSvg from "/assets/header-menu-m.svg";
import classNames from "classnames";
import { useNavigate } from "../util/useNavigate";
import { useIsMobile } from "../util/useWindows";
import MobileMenu from "./MobileMenu";

const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path d="M3.5 6L7.9855 8.6913C8.30219 8.88131 8.69781 8.88131 9.0145 8.6913L13.5 6" stroke="#1172B5" stroke-width="2" stroke-linecap="round" />
  </svg>
);

interface HeaderProps {}

function Header({}: HeaderProps): ReactElement {
  const desktopAnchorClassName = "text-accent-blue uppercase font-circeRounded font-bold cursor-pointer";
  const desktopMenuAnchorClassName = "block hover:underline cursor-pointer";

  const [onHoverPrograms, setOnHoverPrograms] = useState(false);
  const [mobileMenuClicked, setMobileMenuClicked] = useState(false);
  const navi = useNavigate();
  const isMobile = useIsMobile();

  const mobile = (
    <>
      <header className="z-header px-[7.3vw] py-[2.6vw] bg-white h-navigationM">
        <img src={logoMSvg} className="w-[11vw]" />
        <button className="w-[8vw] h-[8vw]" onClick={() => setMobileMenuClicked(!mobileMenuClicked)}>
          <img src={menuMSvg} />
        </button>
      </header>
      {mobileMenuClicked && <MobileMenu onClose={() => setMobileMenuClicked(!mobileMenuClicked)}/>}
    </>
  );

  const desktop = (
    <header className="z-header px-[64px] py-[40px] bg-white h-navigation">
      <img src={logoSvg} className="w-[80px] h-[38.664px]" />

      <div className="flex gap-[48px]">
        <a className={desktopAnchorClassName} onClick={() => navi("/")}>
          Home
        </a>
        <a className={desktopAnchorClassName} onClick={() => navi("/", "#about-us")}>
          About Us
        </a>
        <a className={desktopAnchorClassName} onClick={() => navi("/", "#philosophy")}>
          Philosophy
        </a>
        <a className={desktopAnchorClassName} onClick={() => navi("/", "#curriculum")}>
          Curriculum
        </a>
        <a className={classNames(desktopAnchorClassName, "flex space-x-1 items-center")} onMouseEnter={() => setOnHoverPrograms(true)}>
          <span className="relative">
            Programs
            <ul
              className={classNames("absolute text-accent-blue font-circeRounded font-[400] uppercase space-y-1 p-[8px] bg-white w-fit whitespace-nowrap top-8 left-0 shadow-lg", {
                hidden: !onHoverPrograms,
              })}
              onMouseLeave={() => setOnHoverPrograms(false)}
            >
              <a className={desktopMenuAnchorClassName} onClick={() => navi("/centre-2")}>
                10 - 36 months
              </a>
              <a className={desktopMenuAnchorClassName} onClick={() => navi("/centre-1")}>
                6 – 36 months
              </a>
              <a className={desktopMenuAnchorClassName} onClick={() => navi("/centre-4")}>
                30 months - school age
              </a>
              <a className={desktopMenuAnchorClassName} onClick={() => navi("/centre-3")}>
                After-school CARE
              </a>
            </ul>
          </span>
          <span className={classNames({ "rotate-180": onHoverPrograms })}>
            <Arrow />
          </span>
        </a>
      </div>

      <button className="font-peachy text-[2vh] uppercase text-white bg-accent-blue font-bold py-[1vh] px-[2vh] rounded-full" onClick={() => navi(null, "#contact-us")}>
        Contact Us
      </button>
    </header>
  );

  return isMobile ? mobile : desktop;
}

export default memo(Header);
