import classNames from "classnames";
import React, { memo } from "react";
import type { PropsWithChildren, ReactElement } from "react";
import { useIsMobile } from "../util/useWindows";

function Main({ children }: PropsWithChildren<unknown>): ReactElement {
  const isMobile = useIsMobile();
  return <main className={classNames({ "pt-navigationM": isMobile, "pt-navigation": !isMobile })}>{children}</main>;
}

export default memo(Main);
