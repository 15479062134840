import React, { memo, useState } from "react";
import type { ReactElement } from "react";
import logoMSvg from "/assets/header-logo-m.svg";
import closeMSvg from "/assets/header-close-m.svg";
import classNames from "classnames";
import { useNavigate } from "../util/useNavigate";

const arrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="61" height="56" viewBox="0 0 61 56" fill="none">
    <path d="M7 26L30.4453 41.6302C30.7812 41.8541 31.2188 41.8541 31.5547 41.6302L55 26" stroke="#1172B5" stroke-width="8" stroke-linecap="round" />
  </svg>
);

interface MobileMenuProps {
  onClose: () => void;
}

function MobileMenu({ onClose }: MobileMenuProps): ReactElement {
  const mobileAnchorClassName = "font-peachy uppercase text-[9.6vw] text-accent-blue hover:underline cursor-pointer";
  const mobileProgramsClassName = "font-peachy uppercase text-[4.2vw] text-accent-blue hover:underline cursor-pointer";

  const [programClicked, setProgramClicked] = useState(false);

  const n = useNavigate();

  const navi = (newPath: string | null, selector?: string) => {
    onClose();
    n(newPath, selector);
  };

  return (
    <div className="fixed top-0 left-0 right-0 min-h-screen bg-accent-yellow z-header flex flex-col items-center pt-[10.5vw] overflow-auto">
      <div className="absolute top-0 flex w-full justify-between px-[7.3vw] py-[2.6vw] h-navigationM">
        <img src={logoMSvg} className="w-[11vw]" />
        <button className="w-[8vw] h-[8vw]" onClick={onClose}>
          <img src={closeMSvg} />
        </button>
      </div>

      <button className={mobileAnchorClassName} onClick={() => navi("/")}>
        home
      </button>
      <button className={mobileAnchorClassName} onClick={() => navi("/", "#about-us")}>
        about us
      </button>
      <button className={mobileAnchorClassName} onClick={() => navi("/", "#philosophy")}>
        philosophy
      </button>
      <button className={mobileAnchorClassName} onClick={() => navi("/", "#curriculum")}>
        curriculum
      </button>
      <button className={classNames(mobileAnchorClassName, "flex space-x-1 items-center")} onClick={() => setProgramClicked(!programClicked)}>
        <div className="mr-3">Programs</div>
        <div className={classNames({ "rotate-180": programClicked })}>{arrow}</div>
      </button>
      <ul
        className={classNames("flex flex-col text-center space-y-2 mt-2", {
          hidden: !programClicked,
        })}
      >
        <a className={mobileProgramsClassName} onClick={() => navi("/centre-2")}>
          10 - 36 months
        </a>
        <a className={mobileProgramsClassName} onClick={() => navi("/centre-1")}>
          Under 36 months
        </a>
        <a className={mobileProgramsClassName} onClick={() => navi("/centre-4")}>
          30 months - school age
        </a>
        <a className={mobileProgramsClassName} onClick={() => navi("/centre-3")}>
          After-school CARE
        </a>
      </ul>

      <button className="uppercase font-circeRounded rounded-full py-[3.2vw] px-[8vw] bg-accent-blue text-white text-[4.2vw] mt-[6vw] font-700" onClick={() => navi(null, "#contact-us")}>
        Contact us
      </button>
    </div>
  );
}

export default memo(MobileMenu);
