import React, { ReactElement, useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { dataStore } from "../util/useData";
import { useInterval } from "usehooks-ts";
import { shallow } from "zustand/shallow";

function HeroSlick(): ReactElement | null {
  const [displays] = dataStore((s) => [s.homeDisplay], shallow);
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState<"left" | "right">("right");
  const timer = useRef<any>(null);
  const [stopper, setStopper] = useState<number | null>(4000);

  if (!displays) return null;

  const next = (delay?: boolean) => {
    setDirection("right");
    setActiveIndex((current) => (current === displays.length - 1 ? 0 : current + 1));
    if (delay) {
      delayInterval();
    }
  };

  const delayInterval = () => {
    setStopper(null);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setStopper(4000);
    }, 4000);
  };

  useInterval(() => next(false), stopper);

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return (
    <div className="relative max-w-full overflow-hidden h-full bg-white">
      <div className="absolute h-full -left-[35px] w-[calc(100%+70px)] min-w-[calc(100%+70px)]">
        <AnimatePresence>
          {displays.map((src, index) => {
            const isActive = index === activeIndex;
            const imageClassName = "absolute h-full w-full object-center object-cover";
            return (
              isActive && (
                <motion.img
                  key={`${index}-${src}`} // Use index to ensure unique keys
                  loading="lazy"
                  className={imageClassName}
                  initial={{ opacity: 0, x: 20, zIndex: 1 }}
                  animate={{ opacity: 1, x: 0, zIndex: 1 }}
                  exit={{ opacity: 0, x: -20, zIndex: 1, transition: { duration: 1, delay: 2 } }}
                  transition={{ duration: 1 }}
                  src={src}
                />
              )
            );
          })}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default React.memo(HeroSlick);
