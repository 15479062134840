import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import App from "./src/App";
import Home from "./src/Home";
import ErrorPage from "./src/ErrorPage";
import One from "./src/App/Centres/One";
import Two from "./src/App/Centres/Two";
import Three from "./src/App/Centres/Three";
import Four from "./src/App/Centres/Four";

const container = document.getElementById("root");
const root = createRoot(container!);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "centre-1",
        element: <One />,
      },
      {
        path: "centre-2",
        element: <Two />,
      },
      {
        path: "centre-3",
        element: <Three />,
      },
      {
        path: "centre-4",
        element: <Four />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
