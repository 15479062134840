import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import homeHeroOneSrc from "/assets/home_hero_1.jpeg?as=webp";
import homeHeroTwoSrc from "/assets/home_hero_2.jpeg?as=webp";
import homeHeroThreeSrc from "/assets/home_hero_3.jpeg?as=webp";
import homeHeroFourSrc from "/assets/home_hero_4.jpeg?as=webp";
import { immer } from "zustand/middleware/immer";

export default function useData() {}

export interface DataState {
  homeDisplay: string[] | undefined;
}

const INITIAL_DATA: DataState = {
  homeDisplay: [homeHeroOneSrc, homeHeroTwoSrc, homeHeroThreeSrc, homeHeroFourSrc],
};

interface DataFunc {}

export const dataStore = createWithEqualityFn(
  immer<DataState & DataFunc>((set) => ({
    ...INITIAL_DATA,
  })),
  shallow
);
