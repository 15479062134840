import React, { memo } from "react";
import type { ReactElement } from "react";
import { range } from "ramda";
import centreFourHeroSrc from "/assets/centre-4-hero.svg";
import centreFourHeroBgSrc from "/assets/centre-4-hero-bg.jpeg?as=webp";
import centreFourBodySrc from "/assets/centre-4-body.svg";
import I1 from "/assets/centre-4/1.jpeg?as=webp";
import I2 from "/assets/centre-4/2.jpeg?as=webp";
import I3 from "/assets/centre-4/3.jpeg?as=webp";
import I4 from "/assets/centre-4/4.jpeg?as=webp";
import I5 from "/assets/centre-4/5.jpeg?as=webp";
import I6 from "/assets/centre-4/6.jpeg?as=webp";
import { useIsMobile } from "../../util/useWindows";

import M1 from "/assets/centre-4/m/logo.svg";
import M2 from "/assets/centre-4/m/details.svg";
import M3 from "/assets/centre-4/m/body.svg";

interface FourProps {}

function Four({}: FourProps): ReactElement {
  const isMobile = useIsMobile();
  const desktop = (
    <>
      <div className="h-main-screen flex items-center justify-center" style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url(${centreFourHeroBgSrc}) lightgray 50% / cover no-repeat`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
        <img src={centreFourHeroSrc} className="h-[33vh]" />
      </div>

      <div className="relative grid grid-cols-2 gap-[3vw] px-[6vw] mb-[6vw] pt-[28vw] z-10">
        <img src={centreFourBodySrc} className="absolute top-0 w-full left-0 -z-10" />
        <img src={I1} className="rounded-[32px] object-contain" />
        <img src={I2} className="rounded-[32px] object-contain" />
        <img src={I3} className="rounded-[32px] object-contain" />
        <img src={I4} className="rounded-[32px] object-contain" />
        <img src={I5} className="rounded-[32px] object-contain" />
        <img src={I6} className="rounded-[32px] object-contain" />
      </div>
    </>
  );
  const mobile = (
    <>
      <div className="flex flex-col justify-center items-center space-y-[12vw] p-[8vw] h-main-screen-m" style={{ background: `linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), url(${centreFourHeroBgSrc}) lightgray 50% / cover no-repeat`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }}>
        <img src={M1} className="w-[55vw] mx-auto" />
        <img src={M2} className="w-[54vw] mx-auto" />
      </div>
      <div className="relative grid grid-cols-1 gap-[6.4vw] px-[6vw] mb-[14vw] pt-[112vw] z-10">
        <img src={M3} className="absolute top-0 w-full left-0 -z-10" />
        <img src={I1} className="rounded-[5vw] object-contain" />
        <img src={I2} className="rounded-[5vw] object-contain" />
        <img src={I3} className="rounded-[5vw] object-contain" />
        <img src={I4} className="rounded-[5vw] object-contain" />
        <img src={I5} className="rounded-[5vw] object-contain" />
        <img src={I6} className="rounded-[5vw] object-contain" />
      </div>
    </>
  );

  return <div data-testid="four-div">{isMobile ? mobile : desktop}</div>;
}

export default memo(Four);
