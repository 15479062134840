import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const InfiniteScrollBanner = ({ imageSrc, imageWidth }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      x: ["0%", "-100%"],
      transition: {
        x: {
          repeat: Infinity,
          ease: "linear",
          duration: 40,
        },
      },
    });
  }, [controls]);

  return (
    <div style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
      <motion.div
        animate={controls}
        style={{
          display: "flex",
          width: "200%",
        }}
      >
        <img src={imageSrc} alt="Scrolling Banner" style={{ width: imageWidth }} />
        <img src={imageSrc} alt="Scrolling Banner" style={{ width: imageWidth }} />
      </motion.div>
    </div>
  );
};

export default InfiniteScrollBanner;
