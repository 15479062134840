import React, { memo } from "react";
import type { ReactElement } from "react";
import footerLogoSrc from "/assets/footer_logo.svg";
import footerLogoMSrc from "/assets/footer_logo_m.svg";
import footerBodyMSrc from "/assets/footer-body-m.svg";
import footerCopyRightMSrc from "/assets/footer-copyright.svg";
import footerIndexOneSrc from "/assets/footer_index_1.svg";
import footerIndexTwoSrc from "/assets/footer_index_2.svg";
import footerChatBoxSrc from "/assets/footer_chat_box.svg";
import { Link } from "react-router-dom";
import { useIsMobile } from "../util/useWindows";
import { useNavigate } from "../util/useNavigate";
import { useForm } from "react-hook-form";
import axios from "axios";

interface FooterProps {}

type FormData = {
  name: string | null;
  email: string | null;
  phone: string | null;
  subject: string | null;
  message: string | null;
};

function Footer({}: FooterProps): ReactElement {
  const navi = useNavigate();
  const isMobile = useIsMobile();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      name: null,
      email: null,
      phone: null,
      subject: null,
      message: null,
    },
  });
  const onSubmit = async (data) => {
    try {
      const url = "https://main.d2ouk5inr01hci.amplifyapp.com/api/email";
      await axios.post(url, data);
      alert("Thank you. The form has been submitted.");
      reset();
    } catch (e) {
      alert("Sorry there are some issues on submitting the form. Please try again later.");
    }
    
  };

  const desktopAnchorClassName = "text-accent-blue uppercase font-circeRounded font-bold cursor-pointer";
  const desktop = (
    <>
      <div className="px-[63px] pt-[56px] pb-[76px] grid grid-cols-5 gap-[113px]">
        <div className="col-span-3 grid grid-cols-3 grid-row-3 gap-[30px]">
          <img className="col-span-1 row-span-1" src={footerLogoSrc} />
          <div className="col-span-2" />
          <div className="row-span-2" />
          <div style={{ background: `url(${footerChatBoxSrc})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }} className="w-[416px] h-[296px] relative col-span-2 row-span-2 font-circeRounded text-accent-blue text-[24px] p-[38px] rounded-xl m-auto mr-0 mb-0 font-[700]">
            <div>
              <a className="hover:underline cursor-pointer" href="tel:(604) 780-6581">
                (604) 780-6581
              </a>{" "}
              /{" "}
              <a className="hover:underline cursor-pointer" href="tel:(604) 218-6581">
                (604) 218-6581
              </a>
            </div>
            <div className="lowercase underline mt-[28px] mb-[14px]">
              <a className="hover:underline cursor-pointer" href="mailto:info@happymay.ca">
                info@happymay.ca
              </a>
            </div>
            <div>170-4751 Garden City Rd,</div>
            <div>Richmond, BC, Canada V6X 3M7</div>
          </div>
          <div className="font-peachy text-[12vh] uppercase text-accent-yellow font-bold whitespace-nowrap">Contact Us</div>
        </div>

        {/* @todo: form */}
        <div className="bg-white col-span-2 h-fit rounded-[64px] p-[3vw]">
          <form className="font-circeRounded text-accent-blue text-[1.3vw] opacity-100" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <input {...register("name", { required: true })} placeholder="Name" className="border-b border-accent-blue w-full rounded mb-[1.5vw] placeholder:text-accent-blue bg-transparent" />
            </div>
            <div>
              <input {...register("email", { required: true })} placeholder="Email" className="border-b border-accent-blue w-full rounded mb-[1.5vw] placeholder:text-accent-blue bg-transparent" />
            </div>
            <div>
              <input {...register("phone", { required: true })} placeholder="Phone" className="border-b border-accent-blue w-full rounded mb-[1.5vw] placeholder:text-accent-blue bg-transparent" />
            </div>
            <div>
              <input {...register("subject", { required: true })} placeholder="Subject" className="border-b border-accent-blue w-full rounded mb-[3vw] placeholder:text-accent-blue bg-transparent" />
            </div>
            <div>
              <textarea {...register("message", { required: true })} placeholder="Type your message here..." style={{ resize: "none" }} className="border rounded-[1.8vw] h-[10vw] p-[1vw] border-accent-blue w-full mb-[2vw] placeholder:text-accent-blue bg-transparent" />
            </div>
            <button className="bg-accent-pink text-accent-blue font-800 text-[2vw] w-full rounded-[5vw] mb-[vw] py-[0.7vw]" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>

      <div className="text-[3vh] flex gap-[48px] bg-accent-yellow py-[48px] pl-[68px]">
        <Link to="/" className={desktopAnchorClassName}>
          Home
        </Link>
        <a className={desktopAnchorClassName} onClick={() => navi("/", "#about-us")}>
          About Us
        </a>
        <a className={desktopAnchorClassName} onClick={() => navi("/", "#philosophy")}>
          Philosophy
        </a>
        <a className={desktopAnchorClassName} onClick={() => navi("/", "#curriculum")}>
          Curriculum
        </a>
        <a className={desktopAnchorClassName} onClick={() => navi("/", "#we-offer")}>
          Programs
        </a>
      </div>

      <div className="relative flex w-full h-[41px] overflow-hidden">
        <div className="flex space-x-[30px] absolute left-0">
          <img src={footerIndexOneSrc} className="" />
          <div className="font-peachy text-[2vh] text-accent-yellow uppercase font-bold text-center my-auto">Faith • Hope • Love</div>
        </div>

        <div className="text-accent-yellow uppercase text-center m-auto font-circeRounded text-[1.8vh]">©2024 by Happy May Education Centre Inc.</div>
        <img src={footerIndexTwoSrc} className="absolute right-0" />
      </div>
    </>
  );
  const mobile = (
    <>
      <div className="relative bg-accent-blue px-[8vw] pt-[8vw] pb-[10vw]">
        <img src={footerLogoMSrc} className="mb-[8vw] w-[30vw]" />
        <img src={footerBodyMSrc} className="w-full" />

        <div className="absolute left-[13vw] top-[50vw] font-circeRounded text-accent-blue text-[4.3vw] font-[700]">
          <div>
            <a className="hover:underline cursor-pointer" href="tel:(604) 780-6581">
              (604) 780-6581
            </a>
          </div>
          <div className="lowercase underline mb-[8vw]">
            <a className="hover:underline cursor-pointer" href="mailto:info@happymay.ca">
              info@happymay.ca
            </a>
          </div>
          <div>170-4751 Garden City Rd,</div>
          <div>Richmond, BC, Canada V6X 3M7</div>
        </div>

        <form className="absolute left-[15vw] right-[15vw] top-[100vw] font-circeRounded text-accent-blue text-[4vw] bg-white opacity-100" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input {...register("name", { required: true })} placeholder="Name" className="border-b border-accent-blue w-full rounded mb-[5.5vw] placeholder:text-accent-blue" />
          </div>
          <div>
            <input  {...register("email", { required: true })} placeholder="Email" className="border-b border-accent-blue w-full rounded mb-[5.5vw] placeholder:text-accent-blue" />
          </div>
          <div>
            <input  {...register("phone", { required: true })} placeholder="Phone" className="border-b border-accent-blue w-full rounded mb-[5.5vw] placeholder:text-accent-blue" />
          </div>
          <div>
            <input  {...register("subject", { required: true })} placeholder="Subject" className="border-b border-accent-blue w-full rounded mb-[5.5vw] placeholder:text-accent-blue" />
          </div>
          <div>
            <textarea  {...register("message", { required: true })} placeholder="Type your message here..." style={{ resize: "none" }} className="border rounded-[3vw] h-[28vw] p-[2vw] border-accent-blue w-full mb-[5.5vw] placeholder:text-accent-blue" />
          </div>
          <button className="bg-accent-pink text-accent-blue font-800 text-[6vw] w-full rounded-[5vw] mb-[3vw]" type="submit">
            Submit
          </button>
        </form>
      </div>
      <div className="bg-accent-yellow font-circeRounded font-[700] grid grid-cols-2 gap-[1vw] text-[4vw] text-accent-blue p-[4vw] px-[8vw]">
        <button className="uppercase text-left hover:underline" onClick={() => navi("/")}>
          home
        </button>
        <div />
        <button className="uppercase text-left hover:underline" onClick={() => navi("/", "#about-us")}>
          about us
        </button>
        <button className="uppercase text-right hover:underline" onClick={() => navi("/", "#curriculum")}>
          curriculum
        </button>
        <button className="uppercase text-left hover:underline" onClick={() => navi("/", "#philosophy")}>
          philosophy
        </button>
        <button className="uppercase text-right hover:underline" onClick={() => navi("/", "#we-offer")}>
          programs
        </button>
      </div>
      <div>
        <img src={footerCopyRightMSrc} className="w-full" />
      </div>
    </>
  );
  return (
    <footer id="contact-us" className="z-footer block">
      {isMobile ? mobile : desktop}
    </footer>
  );
}

export default memo(Footer);
