import { useRef } from "react";
import { useLocation, useNavigate as useRouterNavigate } from "react-router-dom";

export function useNavigate() {
  const ref = useRef<any>(null);
  const navi = useRouterNavigate();
  const { pathname } = useLocation();
  return (newPath: string | null, selector?: string) => {
    if (ref.current) clearTimeout(ref.current);
    const shouldRoute = newPath !== null && pathname !== newPath;
    const jumpTo = (s?: string) => {
      if (s === undefined) return; // window.scrollTo({ top: 0, behavior: "smooth" });
      else windowsScrollTo(s);
    };

    if (shouldRoute) {
      navi(newPath);
      ref.current = setTimeout(() => {
        jumpTo(selector);
      }, 500);
    } else jumpTo(selector);
  };
}

function windowsScrollTo(selector: string) {
  const yOffset = -100;
  const element = document.querySelector(selector) as HTMLElement;
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
}
